import { useEffect, useState } from "react";
import { Buffer } from "buffer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import IPokemon from "./interfaces/IPokemon";
import pokemons from "./api/pokemon-tcg-pocket.json";
import PokemonCard from "./models/PokemonCard";

function App() {
  const cards: IPokemon[] = [];
  const boosterFilters = ["Dracaufeu", "Mewtwo", "Pikachu"];
  const rarityFilters = ["1-diamant", "2-diamant", "3-diamant", "4-diamant", "1-etoile", "2-etoile", "3-etoile", "1-couronne"];

  const [playerCards, setPlayerCards] = useState([] as number[]);
  const [filter, setFilter] = useState([] as string[]);
  const [generationFilter, setGenerationFilter] = useState(0);
  const [specialDeck, setSpecialDeck] = useState("");
  const [onlyObtained, setOnlyObtained] = useState(false);
  const [onlyNonObtained, setOnlyNonObtained] = useState(false);
  const [forceMenu, setForceMenu] = useState(false);

  useEffect(() => {
    const storedCards = JSON.parse(localStorage.getItem("cards") as string) || [];
    setPlayerCards(storedCards);
  }, []);

  pokemons.extension["puissance-genetique"].cards.forEach(pokemon => {
    const card: IPokemon = {
      id: pokemon.id,
      extension: "puissance-genetique",
      names: pokemon.names,
      boosters: pokemon.boosters,
      points: pokemon.points,
      rarity: pokemon.rarity,
      type: pokemon.type,
      generation: pokemon.generation,
      related: pokemon.related,
    };
    cards.push(card);
  });
  cards.sort((a, b) => a.extension.localeCompare(b.extension) || a.id - b.id);
  const mewCards = cards
    .filter((card) => card.generation === 1 && card.type === "pokemon")
    .reduce((acc: IPokemon[], current: IPokemon) => {
      const exists = acc.some(item => item.names[0].replace("EX", "").trim() === current.names[0].replace("EX", "").trim())
      if (!exists) {
        acc.push(current)
      }
      return acc;
    }, [] as IPokemon[]);

  function cardClick(cardId: number) {
    let updatedCards = [...playerCards];

    if (updatedCards.includes(cardId)) {
      updatedCards = updatedCards.filter(id => id !== cardId);
    } else {
      updatedCards.push(cardId);
    }

    setPlayerCards(updatedCards);
    localStorage.setItem("cards", JSON.stringify(updatedCards));
  }

  function getRequiredPoints() {
    let points = 0;
    cards.forEach((card) => {
      if (!playerCards.includes(card.id)) {
        points += card.points;
      }
    });

    return points;
  }

  function getCardsPerBooster(booster: string, rarity: string = "") {
    let total = 0;
    cards.forEach((card) => {
      if (card.boosters.includes(booster) && playerCards.includes(card.id) && (rarity === "" || card.rarity === rarity)) {
        total++;
      }
    });

    return total;
  }

  function getTotalCardsPerBooster(booster: string, rarity: string = "") {
    let total = 0;
    cards.forEach((card) => {
      if (card.boosters.includes(booster) && (rarity === "" || card.rarity === rarity)) {
        total++;
      }
    });

    return total;
  }

  function changeFilter(f: string) {
    if (filter.includes(f)) {
      setFilter(filter.filter((r) => r !== f));
    } else {
      setFilter([...filter, f]);
    }
  }

  function getMewCards(player: boolean) {
    let total = 0;
    let cardNames: string[] = [];

    cards.forEach((card) => {
      if (card.generation === 1 && card.type === "pokemon" && card.id !== 283) {
        const name = card.names[0].replace("EX", "").trim();
        if (((player && playerCards.includes(card.id)) || !player) && !cardNames.includes(name)) {
          cardNames.push(name);
          total++;
        }
      }
    });

    return total;
  }

  function getCardsByFilter(all: boolean) {
    const filteredCards: IPokemon[] = [];

    filter.forEach((f) => {
      if (f.includes("-")) {
        filteredCards.push(...cards.filter(pokemon => pokemon.rarity === f && (all || playerCards.includes(pokemon.id))));
      } else {
        filteredCards.push(...cards.filter(pokemon => pokemon.boosters.includes(f) && (all || playerCards.includes(pokemon.id))));
      }
    });

    return filteredCards.length;
  }

  function getPointsByFilter() {
    let filteredPoints: number = 0;

    filter.forEach((f) => {
      if (f.includes("-")) {
        cards.filter(pokemon => pokemon.rarity === f && !playerCards.includes(pokemon.id))
          .forEach((card) => filteredPoints += card.points);
      } else {
        cards.filter(pokemon => pokemon.boosters.includes(f) && !playerCards.includes(pokemon.id))
          .forEach((card) => filteredPoints += card.points);
      }
    });

    return filteredPoints;
  }

  function copyDeck() {
    const base64Text = Buffer.from(localStorage.getItem("cards") as string).toString("base64");
    navigator.clipboard.writeText(base64Text)
      .then(() => toast("Deck copié au presse-papier !"));
  }

  function pasteDeck() {
    const base64Text = prompt("Collez le contenu exporté depuis votre autre appareil :");
    if (base64Text == null || base64Text === "")
      return;

    const decodedDeck: number[] = JSON.parse(atob(base64Text));
    setPlayerCards(decodedDeck);
    localStorage.setItem("cards", JSON.stringify(decodedDeck));
  }

  return (
    <div className="flex min-h-screen">
      {/* Menu de gauche */}
      <div className={forceMenu ? "w-full menu-visible z-30" : "w-1/5 menu-visible invisible"}>
        <div className="overflow-y-auto h-[90%]">
          <h2 className="text-lg font-semibold mb-2">Statistiques</h2>
          <p>Cartes obtenues : <code>{playerCards.length} / {cards.length}</code></p>
          <p title="Cartes requises pour débloquer Mew">Pokédex de Kanto
            : <code>{getMewCards(true)} / {getMewCards(false)}</code></p>
          <p>Points nécessaires : <code>{getRequiredPoints()}</code></p>
          <br />
          <p>Cartes par booster :</p>
          <ul>
            {
              boosterFilters.map((filter) => (
                <div className="relative group">
                  <li className="ml-5 list-disc cursor-pointer hover:font-semibold">
                    {filter} : <code>{getCardsPerBooster(filter)} / {getTotalCardsPerBooster(filter)}</code>
                  </li>
                  <div
                    className="absolute left-0 mt-2 w-48 z-40 bg-white border border-gray-300 shadow-lg opacity-0 group-hover:opacity-100 group-hover:translate-y-0 pointer-events-none group-hover:pointer-events-auto transform translate-y-2 transition-all duration-200 ease-in-out">
                    <h5 className="text-sm font-semibold ml-3">Détail par rareté :</h5>
                    <ul>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "1-diamant")} / {getTotalCardsPerBooster(filter, "1-diamant")}</code> ♦️
                      </li>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "2-diamant")} / {getTotalCardsPerBooster(filter, "2-diamant")}</code> ♦️♦️
                      </li>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "3-diamant")} / {getTotalCardsPerBooster(filter, "3-diamant")}</code> ♦️♦️♦️
                      </li>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "4-diamant")} / {getTotalCardsPerBooster(filter, "4-diamant")}</code> ♦️♦️♦️♦️
                      </li>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "1-etoile")} / {getTotalCardsPerBooster(filter, "1-etoile")}</code> ⭐
                      </li>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "2-etoile")} / {getTotalCardsPerBooster(filter, "2-etoile")}</code> ⭐⭐
                      </li>
                      <li className="ml-10">
                        <code>{getCardsPerBooster(filter, "3-etoile")} / {getTotalCardsPerBooster(filter, "3-etoile")}</code> ⭐⭐⭐
                      </li>
                    </ul>
                  </div>
                </div>

              ))
            }
          </ul>

          <h2 className="text-lg font-semibold mt-5">Filtres</h2>
          <h5 className="font-semibold mb-2 text-blue-700">
            <button onClick={() => {
              setFilter([]);
              setGenerationFilter(0);
              setOnlyObtained(false);
              setOnlyNonObtained(false);
            }}>
              Reset
            </button>
          </h5>

          <div className="grid grid-cols-3 gap-2 mb-3">
            {
              boosterFilters.map((f) => (
                  <button className={filter.includes(f) ? "selected-filter" : "filter"}
                          onClick={() => changeFilter(f)}
                          key={f}>
                    <img src={"/img/assets/" + f.toLowerCase() + ".png"} alt={f} />
                  </button>
                ),
              )
            }
            {
              rarityFilters.map((f) => (
                  <button className={filter.includes(f) ? "selected-filter" : "filter"}
                          onClick={() => changeFilter(f)}
                          key={f}>
                    <img src={"/img/assets/" + f + ".png"} alt={"Rareté " + f} />
                  </button>
                ),
              )
            }
          </div>

          <div className="my-3">
            Génération :
            <select name="generation" id="generation" className="ml-2" value={generationFilter}
                    onChange={(event) => setGenerationFilter(Number.parseInt(event.target.value))}>
              <option value="0">Toutes les cartes</option>
              <option value="1">Gen. 1</option>
              <option value="3">Gen. 3</option>
              <option value="5">Gen. 5</option>
              <option value="6">Gen. 6</option>
              <option value="7">Gen. 7</option>
              <option value="8">Gen. 8</option>
            </select>
          </div>


          <input type="checkbox" onChange={() => setOnlyObtained(!onlyObtained)} checked={onlyObtained}
                 id="onlyObtained" className="mr-1" />
          <label htmlFor="onlyObtained">Cartes obtenues uniquement</label>
          <br />
          <input type="checkbox" onChange={() => setOnlyNonObtained(!onlyNonObtained)} checked={onlyNonObtained}
                 id="onlyNotObtained" className="mr-1" />
          <label htmlFor="onlyNotObtained">Cartes verrouillées uniquement</label>

          {
            (filter.length > 0) ? (
              <div className="mt-2">
                <p>Cartes filtrées : <code>{getCardsByFilter(false)} / {getCardsByFilter(true)}</code></p>
                <p>Points nécessaires : <code>{getPointsByFilter()}</code></p>
              </div>
            ) : null
          }

          <div className="w-full mt-3">
            <h2 className="text-lg font-semibold mb-2">Quêtes spéciales</h2>
            <button className={(specialDeck === "mew" ? "selected-filter" : "filter") + " px-2 py-1"}
                    onClick={() => setSpecialDeck(specialDeck ? "" : "mew")}>
              Kanto (Mew)
            </button>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 p-4 grid grid-cols-2 gap-4" id="settings">
          <button onClick={() => copyDeck()}>Exporter</button>
          <button onClick={() => pasteDeck()}>Importer</button>
        </div>
      </div>

      {/* Contenu central */}
      <div className={forceMenu ? "hidden" : "wbg-white p-1 overflow-auto h-screen md:ml-[20%] md:p-4 md:w-4/5"}>
        {/* Grille de cartes */}
        <div className="grid grid-cols-5 gap-1 md:gap-4">
          {
            (specialDeck === "") ? (
              cards.map((card) => {
                const boosterFilter = filter.filter((item) => !item.includes("-"));
                const rarityFilter = filter.filter((item) => item.includes("-"));

                const matchesRarity = rarityFilter.length === 0 || rarityFilter.includes(card.rarity);
                const matchesBooster = boosterFilter.length === 0 || card.boosters.some((booster) => boosterFilter.includes(booster));
                const matchesGeneration = generationFilter === 0 || (card.generation === generationFilter && card.type === "pokemon");

                const isObtained = playerCards.includes(card.id);
                const matchesObtainedFilter = onlyObtained ? isObtained : true;
                const matchesLockedFilter = onlyNonObtained ? !isObtained : true;

                return (matchesRarity && matchesBooster && matchesGeneration && matchesObtainedFilter && matchesLockedFilter) ? (
                  <div className="bg-blue-200 rounded shadow-md text-center p-1 md:p-2">
                    <PokemonCard
                      pokemon={card}
                      unlocked={isObtained}
                      click={() => cardClick(card.id)}
                      showBoosters={true}
                    />
                  </div>
                ) : null;
              })
            ) : (specialDeck === "mew") ? (
              mewCards.map((card) => {
                const relatedCards: IPokemon[] = []
                card.related.forEach((cardId) => {
                  relatedCards.push(cards.find((c) => c.id === cardId)!!);
                })

                return (
                  <div className="bg-blue-200 rounded shadow-md text-center p-1 md:p-2">
                    <PokemonCard pokemon={card} unlocked={playerCards.includes(card.id)} click={() => {}} />
                    {
                      relatedCards.map((card) => (
                        <img
                          src={`/img/pokemon-tcg-pocket/puissance-genetique/image_${card.id}.png`}
                          className={(playerCards.includes(card.id) ? "" : "opacity-25") + " w-1/5 inline mt-1 mx-1"}
                          alt={card.names[0]}
                        />
                      ))
                    }
                  </div>
                )
              })
            ) : null
          }
        </div>
      </div>

      <button
        onClick={() => {
          setForceMenu(!forceMenu);
        }}
        className="fixed cursor-pointer rounded-full bg-gray-200 p-2 z-50 md:hidden bottom-2 right-2"
      >⚙️
      </button>

      <ToastContainer />
    </div>
  );
}

export default App;
