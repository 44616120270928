import IPokemon from "../interfaces/IPokemon";

function PokemonCard(props: { pokemon: IPokemon, unlocked: boolean, click: () => void, showBoosters?: boolean }) {
  return (
    <div key={props.pokemon.id} className="">
      <div
        className={props.unlocked ? "cursor-pointer" : "bg-gray-600 cursor-pointer rounded-xl"}
        onClick={props.click}
      >
        <img
          src={`/img/pokemon-tcg-pocket/puissance-genetique/image_${props.pokemon.id}.png`}
          className={props.unlocked ? "" : "opacity-25"}
          alt={props.pokemon.names[0]}
        />
      </div>
      {
        props.showBoosters ? (props.pokemon.boosters.map((booster) => (
          <img src={"/img/assets/" + booster.toLowerCase() + ".png"} className="hidden md:inline"
               alt={booster} />
        ))) : null
      }
    </div>
  );
}

export default PokemonCard;